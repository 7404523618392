import tutorService from '../services/tutors'
import userService from '../services/users'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import './Forms.css'

import DeletionModal from './DeletionModal'
import UserSubForm from './UserSubForm'

import { STUDY_TOPICS } from '../config'

const TutorForm = ({ loggedUser, formType, displayMessage }) => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('none')
    const [showModal, setShowModal] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    // Param ID validation, redirect if invalid
    useEffect(() => {
        if (formType === 'edit') {
            if (!id.match(/^[0-9a-fA-F]{24}$/)) {
                navigate('/tutors', { replace: true })
            } else {
                tutorService.getByID(id)
                    .then(tutor => {
                        const tutorFields = {
                            ...tutor,
                            user: tutor.user.id,
                            email: tutor.user.email,
                            name: tutor.user.name,
                            surname: tutor.user.surname,
                            matrikelNr: tutor.user.matrikelNr,
                            phone: tutor.user.phone
                        }
                        setSelectedUser(tutorFields)
                        reset(tutorFields)
                    })
                    .catch(() => {
                        navigate('/tutors/')
                    })
            }
        }
    }, [formType, id, navigate, reset])

    useEffect(() => {
        userService.getAll()
            .then(users => {
                const usersToShow = users.filter(u => {
                    let show = false
                    if (u.role === 'none') {
                        show = true
                    } else if (formType === 'edit' && u.role === 'tutor') {
                        show = true
                    }
                    return show
                } )
                setUsers(usersToShow)
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Users could not be loaded', msg, 'danger')
                setSelectedUser('none')
            })
    }, [displayMessage, formType])

    const topics = STUDY_TOPICS

    const addTutor = (newTutor) => {
        tutorService.create(newTutor)
            .then(() => {
                const successMessage = 'Tutor saved!'
                navigate('/tutors/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Tutor could not be saved', msg, 'danger')
            })
    }

    const updateTutor = (newTutor) => {
        tutorService.update(id, newTutor)
            .then(() => {
                displayMessage(null, 'Tutor updated!', 'success', 5000)
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('Tutor could not be saved', msg, 'danger')
            })
    }

    const onSubmit = values => {
        if (formType ==='edit') {
            updateTutor(values)
        } else {
            addTutor(values)
        }
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('Tutor could not be saved', msg, 'danger')
    }

    const handleDelete = () => {
        if (!id) {
            console.log('Trying to delete tutor without an ID!')
            navigate('/tutors/', { replace: true })
            return
        }
        setShowModal(false)
        tutorService.deleteTutor(id)
            .then(() => {
                const successMessage = 'Tutor deleted!'
                navigate('/tutors/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('Tutor could not be deleted', msg, 'danger')
            })
    }

    return ( loggedUser &&
        <>
        <DeletionModal
            type={'tutor'}
            handleDelete={handleDelete}
            showModal={showModal}
            setShowModal={setShowModal}
        />
        <Container fluid>
            <h1>{formType === 'edit' ? 'Edit Tutor' : 'Add a new Tutor'}</h1>
            <Card><Card.Body><Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                <UserSubForm
                    formType={formType}
                    users={users}
                    register={register}
                    reset={reset}
                    errors={errors}
                    setSelectedUser={setSelectedUser}
                    entityName='tutor'
                />
                <Form.Group className='mb-3' controlId='formTutorTopics'>
                    <Form.Label>Study Topics</Form.Label>
                    <Form.Select multiple name='topics' {...register('topics')}>
                        {topics.map((topic) => (
                            <option key={topic} value={topic}>{topic}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className='mb-3' controlId='formTutorAufsicht'>
                            <Form.Check type='checkbox' label='Aufsicht' name='aufsicht'
                                        {...register('aufsicht')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='schreibassistenz' >
                            <Form.Check type='checkbox' label='Schreibassistenz'
                                        {...register('schreibassistenz')}
                                        />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3' controlId='verteiler' >
                            <Form.Check type='checkbox' label='Verteiler' aria-describedby='verteilerHelpBlock'
                                        {...register('verteiler')}
                            />
                            <Form.Text className='text-muted' id='verteilerHelpBlock'>
                                Is this tutor part of the mailing list?
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className='mb-3' controlId='formTutorMisc'>
                    <Form.Label>Miscellaneous</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={10}
                        placeholder='additional information about the tutor...'
                        name='misc'
                        {...register('misc')}
                    />
                </Form.Group>
                <Button id='btn-save' name='btn-save' variant='primary' type='submit' disabled={selectedUser === 'none'}>
                    Submit
                </Button>
                { formType === 'edit' &&
                    <Button id='btn-delete' name='btn-delete' variant='danger' style={{ marginLeft: 25 }}
                            onClick={() => setShowModal(true) } disabled={selectedUser === 'none'}>
                        Delete Tutor
                    </Button>
                }
                <Button id='btn-back' name='btn-back' variant='outline-dark' style={{ marginLeft: 25 }}
                        onClick={() => navigate('/tutors/')}>
                    Back
                </Button>
            </Form></Card.Body></Card>
        </Container>
        </>
    )
}

export default TutorForm