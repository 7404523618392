import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import loginService from './services/login'

import InfoBox from './components/InfoBox'
import LoadingBlock from './components/LoadingBlock'
import UserBlock from './components/UserBlock'
import MainContent from './components/MainContent'
import NotificationsBlock from './components/NotificationsBlock'
import NavigationSidebar from './components/NavigationSidebar'
import UsersList from './components/UsersList'
import StudentsList from './components/StudentsList'
import StudentForm from './components/StudentForm'
import TutorsList from './components/TutorsList'
import TutorForm from './components/TutorForm'
import RegistrationForm from './components/RegistrationForm'
import ExamsList from './components/ExamsList'
import ExamForm from './components/ExamForm'
import SessionsList from "./components/SessionsList";
import SessionForm from "./components/SessionForm";


const PageHeader = ({user, setUser, notifications, displayMessage}) => {
    return (
        <header>
            <Container fluid>
                <Row>
                    <Col xs={12} md={4} lg={3}>
                        <h1>IIS Exam Service</h1>
                    </Col>
                    <Col lg={4} className='d-none d-lg-block'>
                        <img src='/Header_IIS.png' alt='' className='img-fluid' />
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <NotificationsBlock notificationList={notifications} />
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <UserBlock user={user} setUser={setUser} displayMessage={displayMessage} />
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

const App = (props) => {
    const location = useLocation()
    const navigate = useNavigate()

    // Logged-in user
    const [loadingUser, setLoadingUser] = useState(true)
    const [user, setUser] = useState(null)

    /**
     * This function behaves like setInterval, but executing it the first time immediately
     */
    const setIntervalImmediately = (func, interval) => {
        func()
        return setInterval(func, interval)
    }

    // Check for logged-in user in local storage & periodic check for session expiration
    useEffect(() => {
        const logOutUser = () => {
            window.localStorage.removeItem('loggedExamsAppUser')
            setUser(null)
            loginService.setToken(null)
        }
        const handleUserSession = (user, refresh) => {
            if (user) {
                const now = new Date().getTime()
                if (now >= user.logOutTimestamp) {
                    logOutUser()
                    const successMessage = 'Your session has expired. Please log in again.'
                    navigate('/', { state: { successMessage } })
                } else if (refresh) {
                    setUser(user)
                    loginService.setToken(user.token)
                }
            } else {
                logOutUser()
            }
        }
        const expirationCheckInterval = setIntervalImmediately(() => {
            if (user) {
                handleUserSession(user, false)
            } else {
                const loggedUserJSON = window.localStorage.getItem('loggedExamsAppUser')
                if (loggedUserJSON) {
                    handleUserSession(JSON.parse(loggedUserJSON), true)
                }
            }
        }, 30000) // 30 seconds
        setLoadingUser(false)
        return () => {
            // Clear interval when the component unmounts
            clearInterval(expirationCheckInterval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Main text messages displayed on InfoBox component
    const [infoHeader, setInfoHeader] = useState(null)
    const [infoMessage, setInfoMessage] = useState(null)
    const [infoVariant, setInfoVariant] = useState(null)

    // Clear alerts upon location change
    useEffect(() => {
        setInfoHeader(null)
        setInfoMessage(null)
        setInfoVariant(null)
    }, [location])

    const displayMessage = (header, message, variant, fadeTime=0) => {
        if (message !== null) {
            setInfoHeader(header)
            setInfoMessage(message)
            setInfoVariant(variant)
            if (fadeTime > 0) {
                setTimeout(() => {
                    setInfoHeader(null)
                    setInfoMessage(null)
                    setInfoVariant(null)
                }, fadeTime)
            }
        }
    }

    const { successMessage } = location.state || {}
    useEffect(() => {
        if (successMessage) {
            displayMessage(null, successMessage, 'success', 5000)
        }
    }, [successMessage])

    const notifications = props.notifications ?? []

    const userIsStudent = user && ['admin', 'service', 'tutor', 'student'].includes(user.role)

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <PageHeader user={user} setUser={setUser} notifications={notifications} displayMessage={displayMessage} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={3} xl={2} className='mt-2'>
                    <NavigationSidebar loggedUser={user} />
                </Col>
                <Col xs={12} md={9} xl={10} className='mt-2'>
                <InfoBox header={infoHeader} message={infoMessage} variant={infoVariant} />
                    {loadingUser ? (
                        <LoadingBlock />
                    ):
                    (
                        <Routes>
                            <Route path='/' element={<MainContent loggedUser={user} />} />
                            <Route path='/register'
                                   element={<RegistrationForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                            />
                            <Route path='/user'
                                   element={<RegistrationForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                            />
                            <Route path='/user/:id'
                                   element={<RegistrationForm loggedUser={user} formType={'edit'} displayMessage={displayMessage} />}
                            />
                            { (user && ['admin', 'service'].includes(user.role)) &&
                                <Route path='/users' element={<UsersList user={user} displayMessage={displayMessage} />} />
                            }
                            { (user && ['admin', 'service', 'tutor'].includes(user.role)) &&
                                <>
                                <Route path='/students' element={<StudentsList displayMessage={displayMessage} />} />
                                <Route path='/student'
                                element={<StudentForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                                />
                                <Route path='/student/:id'
                                       element={<StudentForm loggedUser={user} formType={'edit'} displayMessage={displayMessage} />}
                                />
                                </>
                            }
                            { (user && ['admin', 'service'].includes(user.role)) &&
                                <>
                                    <Route path='/tutors' element={<TutorsList displayMessage={displayMessage} />} />
                                    <Route path='/tutor'
                                           element={<TutorForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/tutor/:id'
                                           element={<TutorForm loggedUser={user} formType={'edit'} displayMessage={displayMessage} />}
                                    />
                                </>
                            }
                            { userIsStudent &&
                                <>
                                    <Route path='/exams'
                                           element={<ExamsList loggedUser={user} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/exam'
                                           element={<ExamForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/exam/:id'
                                           element={<ExamForm loggedUser={user} formType={'edit'} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/exam/:examId/sessions'
                                           element={<SessionsList loggedUser={user} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/session/:sessionID'
                                           element={<SessionForm loggedUser={user} formType={'edit'} displayMessage={displayMessage} />}
                                    />
                                    <Route path='/session/add'
                                           element={<SessionForm loggedUser={user} formType={'add'} displayMessage={displayMessage} />}
                                    />
                                </>
                            }
                        </Routes>
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default App
