import axios from 'axios'
import loginService from './login'
import userService from './users'

const getAll = (baseUrl, queryString='') => {
    const request = axios.get(
        `${baseUrl}${queryString}`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const downloadFile = (baseUrl, fileName) => {
    const request = axios({
        method: 'GET',
        url: `${baseUrl}/${fileName}`,
        responseType: 'blob',
        headers: {
            Authorization: loginService.getToken(),
        }
    })
    return request.then(response => response.data)
}

const getActive = baseUrl => {
    const request = axios.get(
        `${baseUrl}active`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const getByID = (baseUrl, id) => {
    const request = axios.get(
        `${baseUrl}/${id}`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const exists = (baseUrl, id) => {
    const request = axios.head(
        `${baseUrl}/${id}`, { headers: { Authorization: loginService.getToken() }})
    return request
        .then(response => {
            if (response.status === 200) {
                return true
            } else if (response.status === 404) {
                return false
            } else {
                return response.data
            }
        })
        .catch(error => error)
}

const deleteByID = (baseUrl, id) => {
    const request = axios.delete(
        `${baseUrl}/${id}`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const create = (baseUrl, newObject, headers = {}) => {
    const request = axios.post(
        baseUrl, newObject, { headers: { ...headers, Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const createInstanceWithUser = (baseUrl, newObject) => {
    // TODO: rollback user update upon error in then()
    return userService.update(newObject.user, {
        name: newObject.name,
        surname: newObject.surname,
        email: newObject.email,
        matrikelNr: newObject.matrikelNr,
        phone: newObject.phone
    }).then(() => {
        const request = axios.post(
            baseUrl, newObject, { headers: { Authorization: loginService.getToken() }})
        return request.then(response => response.data)
    }).catch(error => Promise.reject(error))
}

const update = (baseUrl, instanceID, newObject, headers = {}) => {
    const request = axios.put(
        `${baseUrl}/${instanceID}`,
        newObject,
        { headers: { ...headers, Authorization: loginService.getToken() }
        })
    return request.then(response => response.data)
}


const updateInstanceWithUser = (baseUrl, instanceID, newObject) => {
    // TODO: rollback user update upon error in then()
    return userService.update(newObject.user, {
        name: newObject.name,
        surname: newObject.surname,
        email: newObject.email,
        matrikelNr: newObject.matrikelNr,
        phone: newObject.phone
    }).then(() => {
        const request = axios.put(
            `${baseUrl}/${instanceID}`, newObject, { headers: { Authorization: loginService.getToken() }})
        return request.then(response => response.data)
    }).catch(error => Promise.reject(error))
}

const genericService = {
    getAll,
    downloadFile,
    getByID,
    getActive,
    exists,
    deleteByID,
    create,
    createInstanceWithUser,
    update,
    updateInstanceWithUser
}

export default genericService