import studentService from '../services/students'

import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from "react-bootstrap/Button"

const StudentsList = ({displayMessage}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [students, setStudents] = useState([])
    const [error, setError] = useState(false)

    const { successMessage } = location.state || {}

    const isEmpty = value => value == null || value.length === 0
    const noStudentsStyle = {
        color: 'red',
        textAlign: 'center',
        verticalAlign: 'middle',
    }

    useEffect(() => {
        studentService.getAll()
            .then(students => {
                setStudents(students)
                setError(false)
            })
            .catch(error => {
                setError(true)
                console.error('Error fetching students:', error.response.data.error ?? error.message)
            })
    }, [])

    const memoizedDisplayMessage = useCallback(displayMessage, [displayMessage])
    useEffect(() => {
        if (successMessage) {
            memoizedDisplayMessage(null, successMessage, 'success', 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const counter = students.length > 1 ? `${students.length} students` : null

    return (
        <Container fluid>
            <h1>Students List</h1>
            <div className="d-grid gap-2">
                <Button variant="outline-primary" size="md" onClick={() => navigate(`/student/`)}>
                    Add a new Student
                </Button>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Matrikel Nr.</th>
                        <th>Additional Time</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Topics</th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(students) && students.map(student => (
                        <tr key={student.id}>
                            <td>{student.user.name} {student.user.surname}</td>
                            <td>{student.user.matrikelNr && student.user.matrikelNr}</td>
                            <td>{student.additionalTime && student.additionalTime}%</td>
                            <td>{student.user.email && student.user.email}</td>
                            <td>{student.user.phone && student.user.phone}</td>
                            <td>{student.topics && student.topics.join(', ')}</td>
                            <td>
                                <Button variant="link" onClick={() => navigate(`/student/${student.id}`)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {isEmpty(students) && <tr>
                        <td style={noStudentsStyle} colSpan="6">
                            {error && "Error fetching students list."}
                            {!error && "No students added yet!"}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
                { counter && <p>There are <strong>{counter}</strong></p> }
            </div>
        </Container>
    )
}

export default StudentsList