import React, { useState } from 'react'
import { Button, Form, Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import loginService from '../services/login'

const LoginBlock = ({setUser, displayMessage}) => {
    const [showLoginForm, setShowLoginForm] = useState(false)
    const navigate = useNavigate()

    const handleLoginClick = () => {
        setShowLoginForm(true)
    }

    const handleCancelClick = () => {
        setShowLoginForm(false)
    }

    const handleLogin = user => {
        // Compute timestamp when user will be automatically logged out
        const logOutTimestamp = (new Date()).getTime() + (Number(user.expiresIn) * 1000)
        const loggedInUser = {
            ...user,
            logOutTimestamp
        }
        // Save login details to local storage
        window.localStorage.setItem(
            'loggedExamsAppUser', JSON.stringify(loggedInUser)
        )
        // Save user to App state
        setUser(loggedInUser)
        // Save auth token
        loginService.setToken(loggedInUser.token)
    }

    const onSubmit = values => {
        loginService.login(values)
            .then((result) => {
                handleLogin(result)
                displayMessage(null, 'Welcome back', 'success', 5000)
                setShowLoginForm(false)
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('An error occurred:', msg, 'danger')
            })
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('Login failed', msg, 'danger')
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    return (
        <Container>
            {!showLoginForm ? (
                <Button variant='primary' onClick={handleLoginClick}>
                    Log In / Register
                </Button>
            ) : (
                <Col>
                    <h2>Login</h2>
                    <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                        <Form.Group controlId='formBasicEmail'>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control required type='email' name='email'
                                          placeholder='Enter email'
                                          isInvalid={!!errors.email}
                                          {...register("email",
                                              { required: "Please input your email" ,
                                                  pattern: {
                                                      value: /\S+@\S+\.\S+/,
                                                      message: "Invalid email."
                                                  }
                                              })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) =>
                                    <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback> }
                            />
                        </Form.Group>
                        <Form.Group controlId='formBasicPassword'>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control required type='password' name='password'
                                          placeholder='Password'
                                          isInvalid={!!errors.password}
                                          {...register("password",
                                              { required: "Please input your password" })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="password"
                                render={({ message }) =>
                                    <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback> }
                            />
                        </Form.Group>
                        <Row>
                            <Col style={{ marginTop: 10 }}>
                                <Button variant='primary' type='submit' style={{ marginLeft: 5 }}>
                                    Login
                                </Button>
                                <Button variant='secondary' onClick={handleCancelClick} style={{ marginLeft: 20 }}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Button id='btn-go-register' variant='link'
                                    onClick={() => {
                                        setShowLoginForm(false)
                                        navigate('/register/')
                                    }}
                            >
                                Create new account
                            </Button>
                        </Row>
                    </Form>
                </Col>
            )}
        </Container>
    )
}

export default LoginBlock