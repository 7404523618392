import axios from 'axios'
const baseUrl = '/api/login/'

let token = null

const getToken = () => {
    return token
}

const setToken = newToken => {
    if (newToken) {
        token = `Bearer ${newToken}`
    } else {
        token = null
    }
}

const login = loginData => {
    const request = axios.post(baseUrl, loginData)
    return request.then(response => response.data)
}

const loginService = {
    login,
    getToken,
    setToken
}

export default loginService