import React from 'react'

const CheckMark = ({ status }) => {
    if (status) {
        return <span
            style={{ color: '#5cb85c', fontSize: '1.5em', fontWeight: 'bold', display: 'block' }}
        > ✔ </span>
    } else {
        return <span
            style={{ color: '#d9534f', fontSize: '1.5em', fontWeight: 'bold', display: 'block' }}
        > ✘ </span>
    }
}

export default CheckMark