import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import FormLabel from 'react-bootstrap/FormLabel'
import Spinner from 'react-bootstrap/Spinner'

import AutocompleteTextField from './AutocompleteTextField'
import DeletionModal from './DeletionModal'
import FileUploadInput from './FileUploadInput'

import sessionService from '../services/sessions'
import examService from '../services/exams'
import userService from '../services/users'
import studentService from '../services/students'
import tutorService from '../services/tutors'

import util from '../utils/util'



const SessionForm = ({ loggedUser, formType, displayMessage }) => {

    const navigate = useNavigate()
    const { sessionID } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const [exam, setExam] = useState({})
    const [sessionDuration, setSessionDuration] = useState(0)
    const [fileFields, setFileFields] = useState([])
    const [existingFilePaths, setExistingFilePaths] = useState([])
    const [filesVisibilityDate, setFilesVisibilityDate] = useState('')
    const [student, setStudent] = useState(null)
    const [tutor, setTutor] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const methods = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors }
    } = methods

    const loadExam = async examID => {
        try {
            const examObject = await examService.getByID(examID)
            setExam(examObject)
            setSessionDuration(examObject.durationMinutes ?? 0)
            computeFileVisibilityStart(examObject, 30)
            return examObject
        } catch (error) {
            console.log(error)
            navigate('/')
        } finally {
            setIsLoading(false)
        }
    }

    const loadStudent = async studentID => {
        try {
            const user = await userService.getByID(studentID)
            const studentObject = await studentService.getByID(studentID)
            const student = {
                ...user,
                ...studentObject
            }
            setStudent(student)
            return student
        } catch (error) {
            const msg = error.response?.data?.error ?? error.message
            displayMessage('Student could not be loaded', msg, 'danger')
        }
    }

    const loadTutor = async tutorID => {
        try {
            const user = await userService.getByID(tutorID)
            const tutorObject = await tutorService.getByID(tutorID)
            const tutor = {
                ...user,
                ...tutorObject
            }
            setTutor(tutor)
            return tutor
        } catch (error) {
            const msg = error.response?.data?.error ?? error.message
            displayMessage('Tutor could not be loaded', msg, 'danger')
        }
    }

    const handleAddFileField = () => {
        setFileFields(prevFields => [...prevFields, { id: prevFields.length }])
    }

    useEffect(() => {

        const loadSession = async sessionID => {
            try {
                const sessionObject = await sessionService.getByID(sessionID)
                const examObject = await loadExam(sessionObject.exam)
                const studentObject = await loadStudent(sessionObject.student)
                if ('enrolledTutors' in sessionObject && sessionObject.enrolledTutors.length > 0) {
                    await loadTutor(sessionObject.enrolledTutors[0])
                }
                const session = {
                    ...sessionObject
                }
                session['tutorNotified'] = 'notifiedTutors' in sessionObject && sessionObject.notifiedTutors.length > 0
                if (session.files) {
                    let i = 0
                    const fieldFields = []
                    const filePaths = []
                    for (let f of session.files) {
                        filePaths.push(f)
                        fieldFields.push({id: i})
                        i++
                    }
                    setExistingFilePaths(filePaths)
                    setFileFields(fieldFields)
                    computeSessionDuration(studentObject.additionalTime, examObject.durationMinutes)
                }
                reset(session)
            } catch (error) {
                console.log(error)
                navigate('/')
            } finally {
                setIsLoading(false)
            }
        }

        setIsLoading(true)
        let examId
        if (formType === 'add') {
            examId = searchParams.get('exam')
            if (examId && examId.match(/^[0-9a-fA-F]{24}$/)) {
                loadExam(examId)
            } else {
                navigate('/', { replace: true })
            }
        } else {
            if (sessionID && sessionID.match(/^[0-9a-fA-F]{24}$/)) {
                loadSession(sessionID)
            } else {
                navigate('/', { replace: true })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formType, navigate, reset, searchParams, sessionID])

    const handleDelete = () => {
        if (!sessionID) {
            console.log('Trying to delete session without an ID!')
            navigate(`/exam/${exam.id}/sessions`, { replace: true })
            return
        }
        setShowModal(false)
        sessionService.deleteSession(sessionID)
            .then(() => {
                const successMessage = 'Session deleted!'
                navigate(`/exam/${exam.id}/sessions`, { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('Session could not be deleted', msg, 'danger')
            })
    }

    const addSession = newSession => {
        newSession['createdBy'] = loggedUser.id
        sessionService.create(newSession)
            .then(() => {
                const successMessage = 'Session added!'
                navigate(`/exam/${exam.id}/sessions`, { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Session could not be created', msg, 'danger')
            })
    }

    const editSession = newSession => {
        sessionService.update(newSession.id, newSession)
            .then(() => {
                const successMessage = 'Session updated!'
                navigate(`/exam/${exam.id}/sessions`, { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Session could not be updated', msg, 'danger')
            })
    }

    const onSubmit = values => {
        if (!loggedUser || !('id' in loggedUser)) {
            console.error('User is not logged in!')
            navigate('/')
        }
        const studentName = util.isEmptyObject(student) ? '' : `${student.name} ${student.surname}`
        const studentMatrikelNr = util.isEmptyObject(student) ? '' : student.matrikelNr
        const tutorsName = util.isEmptyObject(tutor) ? '' : `${tutor.name} ${tutor.surname}`
        const newSession = {
            ...values,
            exam: exam.id,
            student: util.isEmptyObject(student) ? null : student.id,
            enrolledTutors: util.isEmptyObject(tutor) ? [] : [tutor.id],
            updatedBy: loggedUser.id,
            filesVisibilityDate: filesVisibilityDate,
            sessionDuration,
            studentName,
            studentMatrikelNr,
            tutorsName
        }
        if (values.canceled) {
            newSession.approved = false
            newSession['approvedBy'] = null
        } else {
            if (values.approved) {
                newSession['approvedBy'] = loggedUser.id
            } else {
                newSession['approvedBy'] = null
            }
        }
        if (values.tutorNotified && !util.isEmptyObject(tutor)) {
            newSession['notifiedTutors'] = [tutor.id]
        } else {
            newSession['notifiedTutors'] = []
        }
        if (values.canceled) {
            newSession['canceledBy'] = loggedUser.id
            newSession['canceledDate'] = new Date()
        }
        if (formType === 'add') {
            addSession(newSession)
        } else {
            editSession(newSession)
        }
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('Session could not be created', msg, 'danger')
    }

    const displayUser = user => `${user.name} ${user.surname} (${user.email})`

    const computeSessionDuration = (additionalTime, examDuration=null) => {
        let duration = 0
        if (exam || examDuration) {
            const intValue = parseInt(additionalTime, 10)
            if (!isNaN(intValue) && intValue > -1) {
                const baseDuration = examDuration ? examDuration : (exam.durationMinutes ?? 0)
                duration = Math.round(baseDuration * (1 + (intValue / 100)))
            }
        }
        setSessionDuration(duration)
    }

    const computeFileVisibilityStart = (exam, minutesBeforeDate)  => {
        let startDate = null
        if (!util.isEmptyObject(exam) && minutesBeforeDate >= 0 && minutesBeforeDate <= 120) {
            const examDate = new Date(exam.startDate)
            startDate = new Date(examDate.getTime() - (minutesBeforeDate*60000))
        }
        setFilesVisibilityDate(startDate)
    }

    const setActiveStudent = async user => {
        if (user) {
            studentService.getByID(user.id)
                .then(student => {
                    setStudent({
                        ...user,
                        ...student
                    })
                    setValue('additionalTime', student.additionalTime ?? 0)
                    computeSessionDuration(student.additionalTime)
                })
                .catch(error => {
                    const msg = error.response?.data?.error ?? error.message
                    displayMessage('Student could not be loaded', msg, 'danger')
                })
        } else {
            setStudent(null)
            setValue('additionalTime', 0)
            computeSessionDuration(0)
        }
    }

    const setActiveTutor = async user => {
        if (user) {
            await tutorService.getByID(user.id)
                .then(tutor => {
                    setTutor({
                        ...user,
                        ...tutor
                    })
                })
                .catch(error => {
                    const msg = error.response?.data?.error ?? error.message
                    displayMessage('Tutor could not be loaded', msg, 'danger')
                })
        } else {
            setTutor(null)
        }
    }

    const userIsService = loggedUser && 'role' in loggedUser && ['admin', 'service'].includes(loggedUser.role)
    const userIsTutor = loggedUser && 'role' in loggedUser && ['admin', 'service', 'tutor'].includes(loggedUser.role)

    return (
        loggedUser && !util.isEmptyObject(exam) &&
        <>
            <Container fluid>
                <h1>{exam.title}: {formType === 'add' ? 'new': 'edit'} Session</h1>
                {isLoading ? (
                    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '200px' }}>
                        <Spinner animation='grow' variant='primary' role='status'>
                            <span className='visually-hidden sr-only'>Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <DeletionModal
                            type={'session'}
                            handleDelete={handleDelete}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                        <Card><Card.Body>
                            <FormProvider { ...methods } >
                                <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                                    <FormLabel>{util.humanDateLong(exam.startDate)}</FormLabel>
                                    <p className='text-muted mb-3'>Fields marked with <span className='text-danger'>*</span> are compulsory</p>
                                    <Form.Group className='mb-3' controlId='formSessionTitle'>
                                        <Form.Label>Exam <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            readOnly disabled required name='exam' aria-describedby='examHelpBlock'
                                            value={`${exam.lva} - ${util.humanDateShort(exam.startDate)}`}
                                        />
                                        <Form.Text className='text-muted' id='examHelpBlock'>
                                            Read-only field. For a different exam, go back to the { }
                                            <Link to='/exams'>exams list</Link>.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId='formSessionRoom'>
                                        <Form.Label>Room</Form.Label>
                                        <Form.Control name='room' aria-describedby='roomHelpBlock'
                                                      {...register('room')}
                                        />
                                        <Form.Text className='text-muted' id='roomHelpBlock'>
                                            Room in which the examination will take place.
                                        </Form.Text>
                                    </Form.Group>
                                    <AutocompleteTextField
                                        fieldName='student'
                                        label='Student'
                                        placeholder='Search by name and/or surname'
                                        compulsory={true}
                                        displayItem={displayUser}
                                        searchEndpoint='/api/users?active=1&role=student&name_like='
                                        activeItem={student}
                                        setActiveItem={setActiveStudent}
                                        displayMessage={displayMessage}
                                    />
                                    <Form.Group className='mb-3' controlId='formSessionAdditionalTime'>
                                        <Form.Label>Additional Time (%) <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type='number' min={0} max={100}
                                                      aria-describedby='additionalTimeHelpBlock'
                                                      isInvalid={!!errors.additionalTime}
                                                      defaultValue={0}
                                                      {...register('additionalTime',
                                                          {
                                                              required: 'Please add an additional time, as a percentage of the standard time',
                                                              validate: (value) => {
                                                                  const intValue = parseInt(value, 10)
                                                                  if (isNaN(intValue)) {
                                                                      return 'Value must be a number'
                                                                  }
                                                                  if (intValue < 0 || intValue > 100) {
                                                                      return 'Value must be between 0 and 100'
                                                                  }
                                                                  return true
                                                              },
                                                          })}
                                                      onChange={event => {
                                                          computeSessionDuration(event.target.value)
                                                          register('additionalTime').onChange(event)
                                                      }}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='additionalTime'
                                            render={({ message }) =>
                                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                                        />
                                        <Form.Text className='text-muted' id='additionalTimeHelpBlock'>
                                            This will replace the additional time given to the student, on top of the standard time duration.<br/>
                                            This session will last a total of <strong>{sessionDuration} minutes</strong>.
                                        </Form.Text>
                                    </Form.Group>
                                    <AutocompleteTextField
                                        fieldName='enrolledTutors'
                                        label='Tutor'
                                        placeholder='Search by name and/or surname'
                                        compulsory={false}
                                        displayItem={displayUser}
                                        searchEndpoint='/api/users?active=1&role=tutor&name_like='
                                        activeItem={tutor}
                                        setActiveItem={setActiveTutor}
                                        displayMessage={displayMessage}
                                    />
                                    { userIsService &&
                                        <Form.Group className='mb-3' controlId='formSessionTutorNotified'>
                                            <Form.Check type='checkbox' label='Tutor Notified?' name='tutorNotified'
                                                        disabled={util.isEmptyObject(tutor)}
                                                        {...register('tutorNotified')}
                                            />
                                        </Form.Group>
                                    }
                                    <fieldset className='mb-3 files-fieldset'>
                                        <legend>Exam Files</legend>

                                        <Alert variant='warning' className='text-muted' id='file-size-warning'>
                                            File size must not exceed <strong>125 MB</strong>.
                                        </Alert>

                                        {fileFields.map((field, index) => (
                                            <Controller
                                                key={field.id}
                                                name={`sessionFile${field.id}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <FileUploadInput
                                                        loggedUser={loggedUser}
                                                        existingFilePath={existingFilePaths[index]}
                                                        label={`File ${index + 1}`}
                                                        fieldName={field.name}
                                                        compulsory={false}
                                                        displayMessage={displayMessage}
                                                        instanceID={sessionID}
                                                    />
                                                )}
                                            />
                                        ))}
                                        { userIsService && <>
                                            <ButtonGroup className='mb-4' size='sm' aria-label='Add or remove additional files'>
                                                <Button variant='dark' onClick={handleAddFileField} disabled={fileFields.length >= 8} >
                                                    Add a File
                                                </Button>
                                            </ButtonGroup>
                                            <Form.Group controlId='formSessionFileVisibility'>
                                                <Form.Label>Files visible from (min.) <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type='number' min={0} max={120}
                                                              aria-describedby='fileVisibilityHelpBlock'
                                                              isInvalid={!!errors.filesVisibleFrom}
                                                              defaultValue={30}
                                                              {...register('filesVisibleFrom',
                                                                  {
                                                                      required: 'Please add a time, in minutes',
                                                                      validate: (value) => {
                                                                          const intValue = parseInt(value, 10)
                                                                          if (isNaN(intValue)) {
                                                                              return 'Value must be a number'
                                                                          }
                                                                          if (intValue < 0 || intValue > 120) {
                                                                              return 'Value must be between 0 and 120 minutes'
                                                                          }
                                                                          return true
                                                                      },
                                                                  })}
                                                              onChange={event => {
                                                                  computeFileVisibilityStart(exam, event.target.value)
                                                                  register('filesVisibleFrom').onChange(event)
                                                              }}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name='filesVisibleFrom'
                                                    render={({ message }) =>
                                                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                                                />
                                                <Form.Text className='text-muted' id='fileVisibilityHelpBlock'>
                                                    Files will become visible on <strong>{util.humanDateLong(filesVisibilityDate)}</strong>.
                                                </Form.Text>
                                            </Form.Group>
                                        </> }
                                    </fieldset>
                                    <Form.Group className='mb-3' controlId='formSessionMisc'>
                                        <Form.Label>Miscellaneous</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={10}
                                            placeholder='additional information about the session...'
                                            name='misc'
                                            {...register('misc')}
                                        />
                                    </Form.Group>

                                    { userIsService &&
                                        <Row>
                                            <Col>
                                                <Form.Group className='mb-3' controlId='formSessionApproved'>
                                                    <Form.Check type='checkbox' label='Approved' name='approved'
                                                                {...register('approved')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className='mb-3' controlId='formSessionCanceled'>
                                                    <Form.Check type='checkbox' label='Canceled' name='canceled'
                                                                {...register('canceled')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }

                                    <Button id='btn-save' name='btn-save' variant='primary' type='submit'>
                                        Submit
                                    </Button>
                                    { formType === 'edit' && userIsService &&
                                        <Button id='btn-delete' name='btn-delete' variant='danger' style={{ marginLeft: 25 }}
                                                onClick={() => setShowModal(true) }>
                                            Delete Session
                                        </Button>
                                    }
                                    <Button id='btn-back' name='btn-back' variant='outline-dark' style={{ marginLeft: 25 }}
                                            onClick={() => navigate(`/exam/${exam.id}/sessions/`)}>
                                        Back
                                    </Button>
                                </Form>
                            </FormProvider>
                        </Card.Body></Card>
                    </>
                )}
            </Container>
        </>
    )
}

export default SessionForm