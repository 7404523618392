import axios from 'axios'
import genericService from './generic'
import loginService from './login'

const baseUrl = '/api/users'

const getAll = (queryString='/') => {
    return genericService.getAll(baseUrl, queryString)
}

const exists = id => {
    return genericService.exists(baseUrl, id)
}

const getByID = id => {
    return genericService.getByID(baseUrl, id)
}

const create = newObject => {
    const request = axios.post(
        baseUrl, newObject, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const deleteUser = id => {
    const request = axios.delete(
        `${baseUrl}/${id}`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const update = (id, newObject) => {
    const request = axios.patch(
        `${baseUrl}/${id}`, newObject, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const userService = {
    getAll,
    exists,
    getByID,
    create,
    deleteUser,
    update
}

export default userService