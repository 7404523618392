import genericService from './generic'
import axios from 'axios'
import loginService from './login'

const baseUrl = '/api/sessions'

const processFiles = sessionObject => {
    const newFiles = []  // New files added to the session by the user
    const existingFiles = []  // Files already in session unchanged by the user
    let i = 0
    while (`sessionFile${i}` in sessionObject) {
        if (sessionObject[`sessionFile${i}`] && sessionObject[`sessionFile${i}`].length > 0) {
            newFiles.push(sessionObject[`sessionFile${i}`][0])
        }
        delete sessionObject[`sessionFile${i}`]
        i++
    }
    if ('files' in sessionObject) {
        for (let file of sessionObject.files) {
            if (file) {
                existingFiles.push(file)
            }
        }
    }
    return {
        ...sessionObject,
        newFiles,
        existingFiles,
    }
}

const getAll = (queryString='/') => {
    return genericService.getAll(baseUrl, queryString)
}

const getByID = id => {
    return genericService.getByID(baseUrl, id)
}

const downloadFile = fileName => {
    return genericService.downloadFile(`${baseUrl}/download/`, fileName)
}

const deleteFile = fileName => {
    return genericService.getAll(`${baseUrl}/deleteFile/${fileName}`)
}

const getExamSessions = (examID, studentID) => {
    let query
    if (studentID) {
        query = `?approved=1&deleted=0&exam=${examID}&student=${studentID}`
    } else {
        query = `?exam=${examID}`
    }
    const request = axios.get(
        `${baseUrl}${query}`, { headers: { Authorization: loginService.getToken() }})
    return request.then(response => response.data)
}

const create = async (newObject) => {
    const newSession = processFiles(newObject)
    return genericService.create(baseUrl, newSession, {
        'Content-Type': 'multipart/form-data',
    })
}

const deleteSession = id => {
    return genericService.deleteByID(baseUrl, id)
}

const update = (id, newObject) => {
    const newSession = processFiles(newObject)
    return genericService.update(baseUrl, id, newSession, {
        'Content-Type': 'multipart/form-data',
    })
}

const sessionService = {
    getAll,
    getByID,
    downloadFile,
    deleteFile,
    getExamSessions,
    create,
    deleteSession,
    update
}

export default sessionService