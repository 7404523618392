import genericService from './generic'

const baseUrl = '/api/exams'

const getAll = (queryString='/') => {
    return genericService.getAll(baseUrl, queryString)
}

const getByID = id => {
    return genericService.getByID(baseUrl, id)
}

const create = async (newObject) => {
    return genericService.create(baseUrl, newObject)
}

const deleteExam = id => {
    return genericService.deleteByID(baseUrl, id)
}

const update = (id, newObject) => {
    return genericService.update(baseUrl, id, newObject)
}

const examService = {
    getAll,
    getByID,
    create,
    deleteExam,
    update
}

export default examService