import React, {useEffect, useState} from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import sessionService from '../services/sessions'


const FileUploadInput = ({ loggedUser, label, compulsory, fieldName, existingFilePath, instanceID, displayMessage }) => {

    const { register, formState: { errors } } = useFormContext()

    const [fileName, setFileName] = useState('')
    const [fileURL, setFileURL] = useState('')

    const handleRemoveFile = async () => {
        if (existingFilePath) {
            if (fileName) {
                try {
                    const session = await sessionService.getByID(instanceID)
                    let fileFound = false
                    if ('files' in session) {
                        for (let i = 0; i < session.files.length; i++) {
                            if (session.files[i] === existingFilePath) {
                                session.files[i] = null
                                await sessionService.update(instanceID, session)
                                await sessionService.deleteFile(fileName)
                                fileFound = true
                                break
                            }
                        }
                    }
                    if (fileFound) {
                        setFileName('')
                        setFileURL('')
                        window.location.reload()  // Hack so that parent component gets refreshed properly
                    } else {
                        displayMessage('Error deleting file', 'File not found in session', 'danger')
                    }
                } catch (error) {
                    const msg = error.response?.data?.error ?? error.message
                    displayMessage('Error deleting file', msg, 'danger')
                }
            } else {
                document.getElementById(`examToolForm${fieldName}`).value = ''
            }
        } else {
            document.getElementById(`examToolForm${fieldName}`).value = ''
        }
    }

    const getFileName = filePath => {
        const pathParts = filePath.split('/')
        return pathParts[pathParts.length - 1]
    }

    useEffect(() => {
        const newFileName = existingFilePath ? getFileName(existingFilePath) : ''
        const createFilesURL = async fileToDownload => {
            const blobby = await sessionService.downloadFile(fileToDownload)
            const blob = new Blob([blobby], {type: 'octet/stream'})
            const objectUrl = window.URL.createObjectURL(blob)
            setFileURL(objectUrl)
        }
        setFileName(newFileName)
        if (newFileName) {
            createFilesURL(newFileName)
                .catch(error => {
                    const msg = error.response?.data?.error ?? error.message
                    displayMessage('Error downloading file', msg, 'danger')
                })
        }
    }, [displayMessage, existingFilePath])

    useEffect(() => {
        const fillAnchor = fileToDownload => {
            const anchor = document.getElementById(`file-down-${fileToDownload}`)
            if (anchor && fileURL) {
                anchor.href = fileURL
                anchor.download = fileToDownload
                // window.URL.revokeObjectURL(objectUrl)
            }
        }
        if (existingFilePath) {
            const newFileName = existingFilePath ? getFileName(existingFilePath) : ''
            fillAnchor(newFileName)
        }
    }, [displayMessage, existingFilePath, fileURL])

    return (
        <Form.Group className='mb-3' controlId={`examToolForm${fieldName}`}>
            <Form.Label>{label} {compulsory && <span className='text-danger'>*</span>}</Form.Label>
            <Row>
                <Col>
                    {
                        fileName && loggedUser &&
                        <div>
                            <a id={`file-down-${fileName}`} href='' download>
                                Download { fileName }
                            </a>
                        </div>
                    }
                    { !fileName && loggedUser && ['admin', 'service'].includes(loggedUser.role) &&
                        <Form.Control
                            type='file'
                            { ...register(fieldName,
                                {
                                    required: compulsory ? 'Please add a file' : false
                                })}
                            isInvalid={!!errors[fieldName]}
                        />
                    }
                </Col>
                <Col>
                    <Button variant='secondary' size='sm' onClick={ handleRemoveFile }>
                        Remove File
                    </Button>
                </Col>
            </Row>
            <ErrorMessage
                errors={errors}
                name={fieldName}
                render={({ message }) =>
                    <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
            />
        </Form.Group>
    )
}

export default FileUploadInput
