import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'

import genericService from '../services/generic'
import util from '../utils/util'


const AutocompleteTextField = ({ fieldName, label, placeholder, compulsory, displayItem, searchEndpoint, activeItem, setActiveItem, displayMessage }) => {
    const { setValue, formState: { errors } } = useFormContext()
    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchStarted, setSearchStarted] = useState(false)

    const fetchSuggestions = async (value) => {
        try {
            setLoading(true)
            const response = await genericService.getAll(searchEndpoint, value)
            setSuggestions(response)
            setLoading(false)
        } catch (error) {
            const msg = error.response?.data?.error ?? error.message
            displayMessage('Error fetching suggestions', msg, 'danger')
            console.error('Error fetching suggestions:', error)
        }
    }

    const handleInputChange = async (value) => {
        if (value.length >= 3) {
            await fetchSuggestions(value)
            setSearchStarted(true)
        } else {
            setSuggestions([])  // Clear suggestions if input is too short
            setSearchStarted(false)
            setActiveItem(null)
        }
    }

    const suggestionClicked = async (event, item) => {
        event.preventDefault()
        let newId = null
        if (!util.isEmptyObject(activeItem)) {
            if (item.id !== activeItem.id) {
                newId = item.id
            }
        } else {
            newId = item.id
        }
        setActiveItem(newId ? item : null)
        setValue(fieldName, newId)
    }

    return (
        <Controller
            name={`${fieldName}-search`}
            render={({ field }) => (
                <Form.Group className='mb-3' controlId={`form${fieldName}`}>
                    <Form.Label>{label} {compulsory && <span className='text-danger'>*</span>}</Form.Label>
                    <Form.Control required placeholder={placeholder}
                                  { ...field }
                                  onChange={e => handleInputChange(e.target.value) }
                                  autoComplete="off"
                    />
                    { (searchStarted || !util.isEmptyObject(activeItem)) &&
                        <Card className='mb-3'>
                            <Card.Header>Choose a {label.toLowerCase()}:</Card.Header>
                            <Card.Body>
                                <ListGroup className='suggestions-list'>
                                    { loading && <span>Loading...</span> }
                                    {searchStarted && suggestions.map((item) => (
                                        <ListGroup.Item
                                            action
                                            active={activeItem ? activeItem.id===item.id : false}
                                            key={item.id}
                                            onClick={e => suggestionClicked(e, item)}>
                                                { displayItem(item) }
                                        </ListGroup.Item>
                                    ))}
                                    { !searchStarted && !util.isEmptyObject(activeItem) &&
                                        <ListGroup.Item
                                            action
                                            active={true}
                                            key={activeItem.id}
                                            onClick={e => suggestionClicked(e, activeItem)}>
                                            { displayItem(activeItem) }
                                        </ListGroup.Item>
                                    }
                                </ListGroup>
                                { searchStarted && suggestions.length === 0 && `No ${label.toLowerCase()}s found.` }
                            </Card.Body>
                        </Card>
                    }
                    <ErrorMessage
                        errors={errors}
                        name={`${fieldName}-search`}
                        render={({ message }) =>
                            <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                    />
                </Form.Group>
            )}
        />
    )
}

export default AutocompleteTextField