const STUDY_TOPICS = [
    'Diplomstudium Rechtswissenschaften',
    'Rechtswissenschaften',
    'Wirtschaftsrecht',
    'Artificial Intelligence',
    'Bioinformatics',
    'Biological Chemistry',
    'Chemistry and Chemical Technology',
    'Elektronik und Informationstechnik',
    'Informatik',
    'Maschinenbau',
    'Mechatronik',
    'Medical Engineering',
    'Molekulare Biowissenschaften',
    'Nachhaltige Kunststofftechnik & Kreislaufwirtschaft',
    'NaWi-Tec',
    'Technische Mathematik',
    'Technische Physik',
    'Betriebswirtschaftslehre',
    'Diplomstudium Wirtschaftspädagogik',
    'International Business Administration',
    'Kulturwissenschaften',
    'Sozialwirtschaft',
    'Soziologie',
    'Statistik und Data Science',
    'Transformation Studies. Art x Science',
    'Wirtschaftsinformatik',
    'Wirtschaftswissenschaften',
    'Humanmedizin',
    'Other'
]

STUDY_TOPICS.sort()

export {
    STUDY_TOPICS
}