import studentService from '../services/students'
import userService from '../services/users'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import './Forms.css'

import DeletionModal from './DeletionModal'
import UserSubForm from './UserSubForm'

import { STUDY_TOPICS } from '../config'

const StudentForm = ({ loggedUser, formType, displayMessage }) => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState('none')
    const [showModal, setShowModal] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    // Param ID validation, redirect if invalid
    useEffect(() => {
        if (formType === 'edit') {
            if (!id.match(/^[0-9a-fA-F]{24}$/)) {
                navigate('/students', { replace: true })
            } else {
                studentService.getByID(id)
                    .then(student => {
                        const studentFields = {
                            ...student,
                            user: student.user.id,
                            email: student.user.email,
                            name: student.user.name,
                            surname: student.user.surname,
                            matrikelNr: student.user.matrikelNr,
                            phone: student.user.phone
                        }
                        setSelectedUser(studentFields)
                        reset(studentFields)
                    })
                    .catch(() => {
                        navigate('/students/')
                    })
            }
        }
    }, [formType, id, navigate, reset])

    useEffect(() => {
        userService.getAll()
            .then(users => {
                const usersToShow = users.filter(u => {
                    let show = false
                    if (u.role === 'none') {
                        show = true
                    } else if (formType === 'edit' && u.role === 'student') {
                        show = true
                    }
                    return show
                } )
                setUsers(usersToShow)
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Users could not be loaded', msg, 'danger')
                setSelectedUser('none')
            })
    }, [displayMessage, formType])

    const topics = STUDY_TOPICS
    const addStudent = (newStudent) => {
        studentService.create(newStudent)
            .then(() => {
                const successMessage = 'Student saved!'
                navigate('/students/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Student could not be saved', msg, 'danger')
            })
    }

    const updateStudent = (newStudent) => {
        studentService.update(id, newStudent)
            .then(() => {
                const successMessage = 'Student updated!'
                navigate('/students', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('Student could not be saved', msg, 'danger')
            })
    }

    const onSubmit = values => {
        if (formType ==='edit') {
            updateStudent(values)
        } else {
            addStudent(values)
        }
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('Student could not be saved', msg, 'danger')
    }

    const handleDelete = () => {
        if (!id) {
            console.log('Trying to delete student without an ID!')
            navigate('/students/', { replace: true })
            return
        }
        setShowModal(false)
        studentService.deleteStudent(id)
            .then(() => {
                const successMessage = 'Student deleted!'
                navigate('/students/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response.data.error ?? error.message
                displayMessage('Student could not be deleted', msg, 'danger')
            })
    }

    return ( loggedUser &&
        <>
            <DeletionModal
                type={'student'}
                handleDelete={handleDelete}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <Container fluid>
                <h1>{formType === 'edit' ? 'Edit Student' : 'Add a new Student'}</h1>
                <Card><Card.Body><Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                    <UserSubForm
                        formType={formType}
                        users={users}
                        register={register}
                        reset={reset}
                        errors={errors}
                        setSelectedUser={setSelectedUser}
                        entityName='student'
                    />
                    <Form.Group className='mb-3' controlId='formStudentEmergency'>
                        <Form.Label>Emergency Contact</Form.Label>
                        <Form.Control type='tel' placeholder='example: 067700000000' name='emergencyPhone'
                                      aria-describedby='emergencyPhoneHelpBlock'
                                      isInvalid={!!errors.emergencyPhone}
                                      { ...register('emergencyPhone') }
                        />
                        <Form.Text className='text-muted' id='emergencyPhoneHelpBlock'>
                            A telephone number to contact in case of emergency.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId="formStudentMoreTime">
                        <Form.Label>Additional Time (%)</Form.Label>
                        <Form.Control type="number" min={0} max={100}
                                      aria-describedby='additionalTimeHelpBlock'
                                      {...register('additionalTime',
                                          {required: 'Please add an additional time' })}
                        />
                        <Form.Text className="text-muted" id='additionalTimeHelpBlock'>
                            Enter a number between 0 and 100.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='formStudentTopics'>
                        <Form.Label>Study Topics</Form.Label>
                        <Form.Select multiple name='topics' {...register('topics')}>
                            {topics.map((topic) => (
                                <option key={topic} value={topic}>{topic}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3' controlId='formStudentBraillezeile'>
                                <Form.Check type='checkbox' label='Braillezeile' name='braillezeile'
                                            {...register('braillezeile')}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='formStudentLaptop' >
                                <Form.Check type='checkbox' label='Laptop'
                                            {...register('laptop')}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='formStudentHandwriting' >
                                <Form.Check type='checkbox' label='Can write by hand'
                                            {...register('handwriting')}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3' controlId='formStudentSchreibassistenz' >
                                <Form.Check type='checkbox' label='Schreibassistenz'
                                            {...register('schreibassistenz')}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className='mb-3' controlId='formStudentMisc'>
                        <Form.Label>Miscellaneous</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={10}
                            placeholder='additional information about the student...'
                            name='misc'
                            {...register('misc')}
                        />
                    </Form.Group>
                    <Button id='btn-save' name='btn-save' variant='primary' type='submit' disabled={selectedUser === 'none'}>
                        Submit
                    </Button>
                    { formType === 'edit' &&
                        <Button id='btn-delete' name='btn-delete' variant='danger' style={{ marginLeft: 25 }}
                                onClick={() => setShowModal(true) } disabled={selectedUser === 'none'}>
                            Delete Student
                        </Button>
                    }
                    <Button id='btn-back' name='btn-back' variant='outline-dark' style={{ marginLeft: 25 }}
                            onClick={() => navigate('/students/')}>
                        Back
                    </Button>
                </Form></Card.Body></Card>
            </Container>
        </>
    )
}

export default StudentForm