import React, { useState, useEffect, useCallback } from 'react'
import {useNavigate, useLocation, useParams, Link} from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './Lists.css'

import CheckMark from './CheckMark'

import sessionService from '../services/sessions'
import examService from '../services/exams'

import util from '../utils/util'

const SessionsList = ({ loggedUser, displayMessage }) => {
    const navigate = useNavigate()
    const { examId } = useParams()
    const location = useLocation()

    const [exam, setExam] = useState({})
    const [sessions, setSessions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const { successMessage } = location.state || {}

    const isEmpty = value => value == null || value.length === 0
    const noSessionsStyle = {
        color: 'red',
        textAlign: 'center',
        verticalAlign: 'middle',
    }

    // Param ID validation, redirect if invalid
    useEffect(() => {
        setLoaded(false)
        if (!examId.match(/^[0-9a-fA-F]{24}$/)) {
            navigate('/exams', { replace: true })
        } else {
            sessionService.getExamSessions(examId, loggedUser.role === 'student'?  loggedUser.id : '')
                .then(sessions => {
                    setSessions(sessions)
                    setError(false)
                })
                .then(() => {
                    examService.getByID(examId)
                        .then(examObject => {
                            setExam(examObject)
                        })
                })
                .catch(error => {
                    setError(true)
                    console.error('Error fetching sessions:', error.response?.data?.error ?? error.message)
                })
                .finally(() => {
                    setLoaded(true)
                })
        }
    }, [examId, navigate])

    const memoizedDisplayMessage = useCallback(displayMessage, [displayMessage])
    useEffect(() => {
        if (successMessage) {
            memoizedDisplayMessage(null, successMessage, 'success', 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const counter = sessions.length > 1 ? `${sessions.length} sessions` : null

    const roleAccess =  loggedUser && ['admin', 'service', 'tutor', 'student'].includes(loggedUser.role)

    return (
        roleAccess && loaded &&
        <Container fluid>
            <h1>Sessions</h1>
            <h4>{ roleAccess &&
                <Link to={`/exam/${exam.id}`}>{exam.title}</Link>
            } - {util.humanDateLong(exam.startDate)}
            </h4>
            <div className='d-grid gap-2'>
                <Button variant='outline-primary' size='md' onClick={() => navigate(`/session/add?exam=${examId}`)}>
                    Add a new Session for this Exam
                </Button>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Approved</th>
                        <th>Duration</th>
                        <th>Student</th>
                        <th>Tutor</th>
                        <th>Tutor Notified</th>
                        <th>Room</th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(sessions) && sessions.map(session => (
                        <tr key={session.id} className={session.canceled ? 'disabled-row' : ''}>
                            {session.canceled ? (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 150, hide: 250 }}
                                    overlay={<Tooltip id={`tooltip-${session.id}`}>Session Canceled</Tooltip>}
                                >
                                    <td><CheckMark status={session.approved}/></td>
                                </OverlayTrigger>
                            ) : (
                                <td><CheckMark status={session.approved}/></td>
                            )}
                            <td>{session.sessionDuration && `${session.sessionDuration} min.`}</td>
                            <td>{session.studentName && `${session.studentMatrikelNr} - ${session.studentName}`}</td>
                            <td>{session.tutorsName && session.tutorsName}</td>
                            <td>{session.notifiedTutors && <CheckMark status={session.notifiedTutors.length > 0}/>}</td>
                            <td>{session.room && session.room}</td>
                            <td>
                                <Button variant='link' onClick={() => navigate(`/session/${session.id}`)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {isEmpty(sessions) && <tr>
                        <td style={noSessionsStyle} colSpan='6'>
                            {error && 'Error fetching sessions list.'}
                            {!error && !loaded && 'Loading...'}
                            {!error && loaded && 'No sessions added yet!'}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
                { counter && <p>There are <strong>{counter}</strong></p> }
            </div>
        </Container>
    )
}

export default SessionsList