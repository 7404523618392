import React, {useState, useEffect, useCallback} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import CheckMark from './CheckMark'

import userService from '../services/users'

import { handleSort } from '../utils/componentUtil'

const UsersList = ({ user, displayMessage }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [users, setUsers] = useState([])
    const [error, setError] = useState(false)
    const [selectedRole, setSelectedRole] = useState('')
    const [sortColumn, setSortColumn] = useState(null)
    const [sortDirection, setSortDirection] = useState(1)

    const { successMessage } = location.state || {}

    const isEmpty = value => value == null || value.length === 0
    const noUsersStyle = {
        color: 'red',
        textAlign: 'center',
        verticalAlign: 'middle',
    }

    const dateToFriendlyFormat = isoDate => {
        const date = new Date(isoDate)
        const now = new Date()
        const timeDifference = now - date
        const seconds = Math.floor(timeDifference / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)
        let humanFriendlyFormat
        if (seconds < 60) {
            humanFriendlyFormat = `${seconds} second${seconds !== 1 ? 's' : ''} ago`
        } else if (minutes < 60) {
            humanFriendlyFormat = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
        } else if (hours < 24) {
            humanFriendlyFormat = `${hours} hour${hours !== 1 ? 's' : ''} ago`
        } else {
            humanFriendlyFormat = `${days} day${days !== 1 ? 's' : ''} ago`
        }
        return humanFriendlyFormat
    }

    useEffect(() => {
        if (user && user.token) {
            userService.getAll()
                .then(users => {
                    setUsers(users)
                    setError(false)
                })
                .catch(error => {
                    setError(true)
                    console.error('Error fetching users:', error)
                })
        }
    }, [user])

    const memoizedDisplayMessage = useCallback(displayMessage, [displayMessage])
    useEffect(() => {
        if (successMessage) {
            memoizedDisplayMessage(null, successMessage, 'success', 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const sortUsers = users => {
        return users.slice().sort((a, b) => {
            if (sortColumn) {
                return (a[sortColumn].localeCompare(b[sortColumn])) * sortDirection
            }
            return 0
        })
    }

    const usersToShow = selectedRole === ''
        ? sortUsers(users)
        : sortUsers(users.filter(u => u.role === selectedRole))

    const roleName = selectedRole === '' ? 'users' : `${selectedRole}s`
    const counter = usersToShow.length > 1 ? `${usersToShow.length} ${roleName}` : null

    return (
        <Container fluid>
            <h1>Users List</h1>
            <div className='d-grid gap-2'>
                <Button variant='outline-primary' size='md' onClick={() => navigate(`/user/`)}>
                    Add a new User
                </Button>
                <div className='mb-2'>
                    <label htmlFor='roleFilter' className='me-2'>
                        Filter by Role:
                    </label>
                    <select
                        id='roleFilter'
                        className='form-select'
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                    >
                        <option value=''>All Roles</option>
                        <option value='admin'>Admin</option>
                        <option value='service'>Service</option>
                        <option value='tutor'>Tutor</option>
                        <option value='student'>Student</option>
                    </select>
                </div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Active</th>
                        <th className='sortable-header' onClick={() => handleSort('name', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Name
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('surname', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Surname
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('email', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Email
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('role', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Role
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('lastLogin', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Last Login
                        </th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(usersToShow) && usersToShow.map(user => (
                        <tr key={user.id}>
                            <td><CheckMark status={user.active} /></td>
                            <td>{user.name && user.name}</td>
                            <td>{user.surname && user.surname}</td>
                            <td>{user.email && user.email}</td>
                            <td>{user.role && user.role}</td>
                            <td> { user.lastLogin  ? dateToFriendlyFormat(user.lastLogin) : 'never' } </td>
                            <td>
                                <Button variant='link' onClick={() => navigate(`/user/${user.id}`)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {isEmpty(usersToShow) && <tr>
                        <td style={noUsersStyle} colSpan='6'>
                            {error && 'Error fetching users list.'}
                            {!error && 'No users'}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
                { counter && <p>There are <strong>{counter}</strong></p> }
            </div>
        </Container>
    )
}

export default UsersList