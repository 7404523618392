import React from 'react'
import { Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'

import util from '../utils/util'

const UserSubForm = ({ formType, users, register, reset, errors, setSelectedUser, entityName }) => {

    let capitalEntity = util.capitalizeFirstLetter(entityName)
    const allRoles = ['admin', 'service', 'tutor', 'student']
    const hiddenRoles = allRoles.filter(r => r !== entityName)

    const handleUserChange = selectedUserId => {
        const newUser = users.find(user => user.id === selectedUserId)
        setSelectedUser(newUser ?? 'none')
        if (newUser) {
            reset(newUser)
        } else {
            reset({
                surname: '',
                name: '',
                matrikelNr: '',
                phone: '',
                email: ''
            })
        }
    }

    return (
        <fieldset className="user-fields-fieldset">
            <legend>
                <span>User Data</span>
            </legend>
            <Alert variant="warning" className='text-muted' id="user-data-warning">
                <strong>Warning:</strong> modifying these fields will change the user account of the {capitalEntity}!
            </Alert>
            <Form.Group controlId="selectedUserId" style={{ marginBottom: 15 }}>
                <Form.Label>User Account</Form.Label>
                <Form.Select
                    name="user"
                    aria-describedby='userSelectHelpBlock'
                    disabled={formType === 'edit'}
                    {...register('user', {
                        validate: value => {
                            if (value === 'none') {
                                return 'Please select a user account.';
                            }
                            return true;
                        },
                    })}
                    onChange={event => {
                        handleUserChange(event.target.value);
                        register('user').onChange(event);
                    }}
                >
                    <option value="none">None</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.name} {user.surname} ({user.email})
                        </option>
                    ))}
                </Form.Select>
                <Form.Text className='text-muted' id='userSelectHelpBlock'>
                    All {entityName}s must have an associated user account in the system. { }
                    Note that users with { util.commaSeparatedString(hiddenRoles) } roles{
                    formType === 'edit' ? ', and already existing ' + entityName + 's, ' : ''
                } are not shown.<br/>
                    <Link to='/register'>Create new account</Link>.
                </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formUserSurname'>
                <Form.Label>Surname</Form.Label>
                <Form.Control placeholder='example: Mustermann' name='surname'
                              aria-describedby='nameHelpBlock'
                              isInvalid={!!errors.surname}
                              {...register('surname',
                                  { required: 'Please add a surname' })}
                />
                <ErrorMessage
                    errors={errors}
                    name='surname'
                    render={({ message }) =>
                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formUserName'>
                <Form.Label>First Name</Form.Label>
                <Form.Control placeholder='example: Max' name='name'
                              aria-describedby='surnameHelpBlock'
                              isInvalid={!!errors.name}
                              {...register('name',
                                  { required: 'Please add a name' })}
                />
                <ErrorMessage
                    errors={errors}
                    name='name'
                    render={({ message }) =>
                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formUserMatrikelNr'>
                <Form.Label>Matrikel Nr.</Form.Label>
                <Form.Control required placeholder='example: k12345678' name='matrikelNr'
                              aria-describedby='mnHelpBlock'
                              isInvalid={!!errors.matrikelNr}
                              {...register('matrikelNr',
                                  {
                                      required: 'Please add a Matrikel Nr.',
                                      pattern: {
                                          value: /^(a?k|vk?)\d{6,8}$/,
                                          message: 'Incorrect format.'
                                      }
                                  })}
                />
                <ErrorMessage
                    errors={errors}
                    name='matrikelNr'
                    render={({ message }) =>
                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formUserPhone'>
                <Form.Label>Telephone</Form.Label>
                <Form.Control required type='tel' placeholder='example: 067700000000' name='phone'
                              aria-describedby='phoneHelpBlock'
                              isInvalid={!!errors.phone}
                              {...register('phone',
                                  { required: 'Please add a telephone number' })}
                />
                <ErrorMessage
                    errors={errors}
                    name='phone'
                    render={({ message }) =>
                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formUserEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control required type='email' placeholder='example: max.mustermann@example.com'
                              aria-describedby='emailHelpBlock' name='email'
                              isInvalid={!!errors.email}
                              {...register('email',
                                  { required: 'Please add an email' ,
                                      pattern: {
                                          value: /\S+@\S+\.\S+/,
                                          message: 'Invalid email.'
                                      }
                                  })}
                />
                <ErrorMessage
                    errors={errors}
                    name='email'
                    render={({ message }) =>
                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                />
                <Form.Text className='text-muted' id='emailHelpBlock'>
                    Doubles as username.
                </Form.Text>
            </Form.Group>
        </fieldset>
    );
};

export default UserSubForm
