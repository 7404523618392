import React, { useState } from 'react';
import { Button, Badge, Modal, ListGroup } from 'react-bootstrap';
import './NotificationsBlock.css';

const NotificationsBlock = ({notificationList}) => {
    const [showNotifications, setShowNotifications] = useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setShowNotifications(true)}>
                Notifications{' '}
                <Badge bg="secondary">{notificationList.length}</Badge>
                <span className="visually-hidden">unread notifications</span>
            </Button>

            <Modal
                size="lg"
                show={showNotifications}
                onHide={() => setShowNotifications(false)}
                aria-labelledby="notifications-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="notifications-title">All Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup variant="flush">
                    {notificationList.map((notification) => (
                        <ListGroup.Item
                            key={notification.id}
                            className={`notification-item notif-level-${notification.level}`}
                        >
                            <div className="notification-content">{notification.content}</div>
                        </ListGroup.Item>
                    ))}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NotificationsBlock;