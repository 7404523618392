import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import DeletionModal from './DeletionModal'

import examService from '../services/exams'

import util from '../utils/util'
import { STUDY_TOPICS } from '../config'


const ExamForm = ({ loggedUser, formType, displayMessage }) => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const abhaltungsArten = ['moodle', 'online', 'presence', 'hybrid', 'other']
    const topics = STUDY_TOPICS

    const methods = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = methods

    // Param ID validation, redirect if invalid
    useEffect(() => {
        setIsLoading(true)
        if (formType === 'edit') {
            if (!id.match(/^[0-9a-fA-F]{24}$/)) {
                navigate('/exams', { replace: true })
            } else {
                examService.getByID(id)
                    .then(exam => {
                        const examDate = exam.startDate.split('T')
                        const fields = {
                            ...exam,
                            date: examDate[0],
                            time: examDate[1].substring(0, 5)  // Remove seconds
                        }
                        reset(fields)
                    })
                    .catch(() => {
                        navigate('/exams/')
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        } else {
            setIsLoading(false)
        }
    }, [formType, id, navigate, reset])

    const addExam = newExam => {
        examService.create(newExam)
            .then(() => {
                const successMessage = 'Exam created!'
                navigate('/exams/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Exam could not be created', msg, 'danger')
            })
    }

    const updateExam = (newExam) => {
        examService.update(id, newExam)
            .then(() => {
                displayMessage(null, 'Exam updated!', 'success', 5000)
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Exam could not be saved', msg, 'danger')
            })
    }

    const onSubmit = values => {
        if (!loggedUser || !('id' in loggedUser)) {
            console.error('User is not logged in!')
            navigate('/')
        }
        const newExam = {
            ...values,
            updatedBy: loggedUser.id
        }
        if (formType === 'edit') {
            updateExam(newExam)
        } else {
            newExam['createdBy'] = loggedUser.id
            addExam(newExam)
        }
    }

    const handleDelete = () => {
        if (!id) {
            console.log('Trying to delete exam without an ID!')
            navigate('/exams/', { replace: true })
            return
        }
        if (!loggedUser || !('id' in loggedUser)) {
            console.error('User is not logged in!')
            navigate('/')
        }
        setShowModal(false)
        examService.deleteExam(id)
            .then(() => {
                const successMessage = 'Exam deleted!'
                navigate('/exams/', { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('Exam could not be deleted', msg, 'danger')
            })
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('The exam was not created', msg, 'danger')
    }

    const title = formType === 'add' ? 'Add new Exam' : 'Edit Exam'

    return (
        loggedUser &&
        <>
            <DeletionModal
                type={'exam'}
                handleDelete={handleDelete}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <Container fluid>
                <h1>{title}</h1>
                {isLoading ? (
                    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '200px' }}>
                        <Spinner animation='grow' variant='primary' role='status'>
                            <span className='visually-hidden sr-only'>Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                <Card><Card.Body>
                    <FormProvider { ...methods } >
                        <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                        <p className='text-muted mb-3'>Fields marked with <span className='text-danger'>*</span> are compulsory</p>
                        <Form.Group className='mb-3' controlId='formExamTitle'>
                            <Form.Label>Title <span className='text-danger'>*</span></Form.Label>
                            <Form.Control required placeholder='example: UV Aktuelle Themen der Bildungsforschung' name='title'
                                          isInvalid={!!errors.title}
                                          {...register('title',
                                              { required: 'Please add an exam title' })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='title'
                                render={({ message }) =>
                                    <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                            />
                        </Form.Group>
                        <Form.Group controlId='selectMode' className='mb-3'>
                            <Form.Label>Abhaltungsart <span className='text-danger'>*</span></Form.Label>
                            <Form.Select
                                name='abhaltungsArt'
                                {...register('abhaltungsArt',
                                    { required: 'Please add a mode for the exam' }
                                )}
                            >
                                <option value='none'>None</option>
                                {abhaltungsArten.map((mode) => (
                                    <option key={mode} value={mode}>
                                        { util.capitalizeFirstLetter(mode) }
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formExamTopics'>
                            <Form.Label>Exam Topic <span className='text-danger'>*</span></Form.Label>
                            <Form.Select name='topic' {...register('topic')}>
                                {topics.map((topic) => (
                                    <option key={topic} value={topic}>{topic}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formExamLVA'>
                            <Form.Label>LVA-Nr. <span className='text-danger'>*</span></Form.Label>
                            <Form.Control required placeholder='example: 123.456' name='lva'
                                          isInvalid={!!errors.lva}
                                          {...register('lva',
                                              { required: 'Please add a LVA' })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='lva'
                                render={({ message }) =>
                                    <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formExamProf'>
                            <Form.Label>Professor / Kursleiter</Form.Label>
                            <Form.Control required placeholder='example: Prof. Dr. Mustermann' name='professor'
                                          isInvalid={!!errors.professor}
                                          { ...register('professor') }
                            />
                            <ErrorMessage
                                errors={errors}
                                name='professor'
                                render={({ message }) =>
                                    <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlId='formExamDate'>
                                    <Form.Label>Exam Date <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control type='date' name='date'
                                                  isInvalid={!!errors.date}
                                                  { ...register('date',
                                                  {
                                                      required: 'Please add a date for the exam',
                                                      validate: (value) => {
                                                          const chosenDate = new Date(value)
                                                          chosenDate.setHours(23, 59, 59)
                                                          const dateNow = new Date()
                                                          if (chosenDate < dateNow) {
                                                              return 'Date is in the past!'
                                                          } else {
                                                              return true
                                                          }
                                                      }})}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name='date'
                                        render={({ message }) =>
                                            <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label>Starting Time <span className='text-danger'>*</span></Form.Label>
                                <Form.Control type='time' name='time'
                                              isInvalid={!!errors.time}
                                              { ...register('time',
                                                  { required: 'Please add a starting time' })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='time'
                                    render={({ message }) =>
                                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                                />
                            </Col>
                            <Col>
                                <Form.Label>Duration <span className='text-danger'>*</span></Form.Label>
                                <Form.Control type='text' name='durationMinutes' aria-describedby='durationHelpBlock'
                                              isInvalid={!!errors.durationMinutes}
                                              { ...register('durationMinutes',
                                                  {
                                                      required: 'Please add a duration',
                                                      pattern: {
                                                              value: /^\d{1,3}$/,
                                                              message: 'Invalid duration (minutes)'
                                                          },
                                                      validate: {
                                                          notTooLong: (value) =>
                                                              parseInt(value) <= 480 || 'Duration is too long',
                                                      }}
                                              )}
                                />
                                <Form.Text className='text-muted' id='durationHelpBlock'>
                                    Standard length of the exam, in minutes.
                                </Form.Text>
                                <ErrorMessage
                                    errors={errors}
                                    name='durationMinutes'
                                    render={({ message }) =>
                                        <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                                />
                            </Col>
                        </Row>
                        <Form.Group className='mb-3' controlId='formExamMisc'>
                            <Form.Label>Miscellaneous</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={10}
                                placeholder='additional information about the exam...'
                                name='misc'
                                {...register('misc')}
                            />
                        </Form.Group>
                        <Button id='btn-save' name='btn-save' variant='primary' type='submit'>
                            Submit
                        </Button>
                        { formType === 'edit' &&
                            <Button id='btn-delete' name='btn-delete' variant='danger' style={{ marginLeft: 25 }}
                                    onClick={() => setShowModal(true) }>
                                Delete Exam
                            </Button>
                        }
                        <Button id='btn-back' name='btn-back' variant='outline-dark' style={{ marginLeft: 25 }}
                                onClick={() => navigate('/exams/')}>
                            Back
                        </Button>
                    </Form>
                </FormProvider>
                </Card.Body></Card>
                )}
            </Container>
        </>
    )
}

export default ExamForm