import tutorService from '../services/tutors'

import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from "react-bootstrap/Button"

const TutorsList = ({displayMessage}) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [tutors, setTutors] = useState([])
    const [error, setError] = useState(false)

    const { successMessage } = location.state || {}

    const isEmpty = value => value == null || value.length === 0
    const noTutorsStyle = {
        color: 'red',
        textAlign: 'center',
        verticalAlign: 'middle',
    }

    useEffect(() => {
        tutorService.getAll()
            .then(tutors => {
                setTutors(tutors)
                setError(false)
            })
            .catch(error => {
                setError(true)
                console.error('Error fetching tutors:', error.response.data.error ?? error.message)
            })
    }, [])

    const memoizedDisplayMessage = useCallback(displayMessage, [displayMessage])
    useEffect(() => {
        if (successMessage) {
            memoizedDisplayMessage(null, successMessage, 'success', 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const counter = tutors.length > 1 ? `${tutors.length} tutors` : null

    return (
        <Container fluid>
            <h1>Tutors List</h1>
            <div className="d-grid gap-2">
                <Button variant="outline-primary" size="md" onClick={() => navigate(`/tutor/`)}>
                    Add a new Tutor
                </Button>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Matrikel Nr.</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Topics</th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(tutors) && tutors.map(tutor => (
                        <tr key={tutor.id}>
                            <td>{tutor.user.name} {tutor.user.surname}</td>
                            <td>{tutor.user.matrikelNr && tutor.user.matrikelNr}</td>
                            <td>{tutor.user.email && tutor.user.email}</td>
                            <td>{tutor.user.phone && tutor.user.phone}</td>
                            <td>{tutor.topics && tutor.topics.join(', ')}</td>
                            <td>
                                <Button variant="link" onClick={() => navigate(`/tutor/${tutor.id}`)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {isEmpty(tutors) && <tr>
                        <td style={noTutorsStyle} colSpan="6">
                            {error && "Error fetching tutors list."}
                            {!error && "No tutors added yet!"}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
                { counter && <p>There are <strong>{counter}</strong></p> }
            </div>
        </Container>
    )
}

export default TutorsList