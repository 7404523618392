import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'

import LoginBlock from './LoginBlock'
import loginService from "../services/login";

const UserBlock = ({ user, setUser, displayMessage }) => {

    const navigate = useNavigate()

    const handleLogout = () => {
        window.localStorage.removeItem('loggedExamsAppUser')
        setUser(null)
        loginService.setToken(null)
        const successMessage = 'You have been logged out.'
        navigate('/', { state: { successMessage } })
    }

    if (user) {
        return (
            <Container fluid>
                <Row className='align-items-center'>
                    <Col xs={12} xxl={9}>
                        <span>
                            {user.email}  <Badge pill bg='success' text='light'>Logged in</Badge>
                        </span>
                    </Col>
                    <Col xs={12} xxl={3}>
                        <Button variant='link' size='sm' onClick={handleLogout}>
                            Log Out
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return <LoginBlock setUser={setUser} displayMessage={displayMessage} />
    }
}

export default UserBlock