import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";

import App from './App';


// TODO: fetch from DB
const notifications = [
    {
        id: 1,
        content: 'Exam: Innovation in Mechatronics was canceled by student.',
        level: 3,
        url: 'www.google.com'
    },
    {
        id: 2,
        content: 'Exam: Innovation in Mechatronics. A tutor was added.',
        level: 2,
        url: 'www.google.com'
    },
    {
        id: 3,
        content: 'Exam: Innovation in Mechatronics was approved by service team.',
        level: 1,
        url: 'www.google.com'
    }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App notifications={notifications} />
        </BrowserRouter>
    </React.StrictMode>
);
