import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import DeletionModal from './DeletionModal'

import userService from '../services/users'
import util from '../utils/util'


const RegistrationForm = ({ loggedUser, formType, displayMessage }) => {

    const navigate = useNavigate()
    const { id } = useParams()

    const [showModal, setShowModal] = useState(false)
    const [isPasswordTyped, setIsPasswordTyped] = useState(false)

    const backURL = loggedUser && (loggedUser.role === 'admin' || loggedUser.role === 'service')
        ? '/users/'
        : '/'

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setIsPasswordTyped(newPassword.length > 0);
    }

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {}
    })

    // Param ID validation, redirect if invalid
    useEffect(() => {
        if (formType === 'edit') {
            if (!id.match(/^[0-9a-fA-F]{24}$/)) {
                navigate(backURL, { replace: true })
            } else {
                userService.getByID(id)
                    .then(user => {
                        reset(user)
                    })
                    .catch(() => {
                        navigate(backURL)
                    })
            }
        }
    }, [formType, id, navigate, backURL, reset])

    const password = watch('password')

    const addUser = newUser => {
        userService.create(newUser)
            .then(() => {
                const successMessage = 'User created!'
                navigate(backURL, { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('User could not be created', msg, 'danger')
            })
    }

    const updateUser = (newUser) => {
        userService.update(id, newUser)
            .then(() => {
                displayMessage(null, 'User updated!', 'success', 5000)
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('User could not be saved', msg, 'danger')
            })
    }

    const onSubmit = values => {
        if (formType === 'edit') {
            updateUser(values)
        } else {
            addUser(values)
        }
    }

    const handleDelete = () => {
        if (!id) {
            console.log('Trying to delete user without an ID!')
            navigate(backURL, { replace: true })
            return
        }
        setShowModal(false)
        userService.deleteUser(id)
            .then(() => {
                const successMessage = 'User deleted!'
                navigate(backURL, { state: { successMessage } })
            })
            .catch(error => {
                const msg = error.response?.data?.error ?? error.message
                displayMessage('User could not be deleted', msg, 'danger')
            })
    }

    const onError = (error) => {
        const msg = Object.values(error).map(obj => obj.message).join('; ')
        displayMessage('The account was not created', msg, 'danger')
    }

    const title = formType === 'add' ? 'Create new Account' : 'Edit User'
    const passwordRequired = formType === 'add' ||  (loggedUser && id === loggedUser.id)
    const roleAccess =  loggedUser && ['admin', 'service'].includes(loggedUser.role)
    const userRoleOptions = ['service']

    return (
        <>
            <DeletionModal
                type={'user'}
                handleDelete={handleDelete}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <Container fluid>
                <h1>{title}</h1>
                <Card><Card.Body><Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                    <p className='text-muted mb-3'>Fields marked with <span className='text-danger'>*</span> are compulsory</p>
                    <Form.Group className='mb-3' controlId='formUserEmail'>
                        <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                        <Form.Control required type='email' placeholder='max.mustermann@example.com'
                                      aria-describedby='emailHelpBlock' name='email'
                                      isInvalid={!!errors.email}
                                      {...register('email',
                                          { required: 'Please add an email' ,
                                              pattern: {
                                                  value: /\S+@\S+\.\S+/,
                                                  message: 'Invalid email.'
                                              }
                                          })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='email'
                            render={({ message }) =>
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                        />
                        <Form.Text className='text-muted' id='emailHelpBlock'>
                            Doubles as username.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formUserPassword'>
                        <Form.Label>Password { passwordRequired && <span className='text-danger'>*</span>}</Form.Label>
                        <Form.Control
                            required={passwordRequired}
                            type='password'
                            placeholder='Enter password'
                            name='password'
                            isInvalid={!!errors.password}
                            aria-describedby={passwordRequired ? null : 'pwdHelpBlock'}
                            {...register('password', {
                                required: passwordRequired ? 'Please enter a password': false,
                                minLength: {
                                    value: 8,
                                    message: 'Password must be at least 8 characters long',
                                },
                                onChange: (e) => {
                                    handlePasswordChange(e)
                                },
                            })}
                        />
                        { !passwordRequired && (
                            <Form.Text className='text-muted' id='pwdHelpBlock'>
                                Leave empty to keep the current password unchanged.
                            </Form.Text>
                        )}
                        <ErrorMessage
                            errors={errors}
                            name='password'
                            render={({ message }) => (
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback>
                            )}
                        />
                    </Form.Group>
                    {isPasswordTyped && (
                        <Form.Group className='mb-3' controlId='formUserConfirmPassword'>
                            <Form.Label>Confirm Password <span className='text-danger'>*</span></Form.Label>
                            <Form.Control
                                required
                                type='password'
                                placeholder='Confirm password'
                                name='confirmPassword'
                                isInvalid={!!errors.confirmPassword}
                                {...register('confirmPassword', {
                                    validate: (value) => value === password || 'Passwords do not match',
                                })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='confirmPassword'
                                render={({ message }) => (
                                    <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback>
                                )}
                            />
                        </Form.Group>
                    )}
                    { roleAccess &&
                        <Form.Group controlId="selecteRole" className='mb-3'>
                            <Form.Label>Special Role</Form.Label>
                            <Form.Select
                                name="role"
                                aria-describedby='roleHelpBlock'
                                {...register('role',
                                    { required: 'Please add a user role' }
                                )}
                            >
                                <option value="none">None</option>
                                {userRoleOptions.map((role) => (
                                    <option key={role} value={role}>
                                        { util.capitalizeFirstLetter(role) }
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Text className='text-muted' id='roleHelpBlock'>
                                You can add Tutor and Student roles later on by creating a { }
                                <Link to='/tutors'>Tutor</Link> or a <Link to='/students'>Student</Link> { }
                                associated with this account.
                            </Form.Text>
                        </Form.Group>
                    }
                    <Form.Group className='mb-3' controlId='formUserSurname'>
                        <Form.Label>Surname <span className='text-danger'>*</span></Form.Label>
                        <Form.Control required placeholder='Mustermann' name='surname'
                                      isInvalid={!!errors.surname}
                                      {...register('surname',
                                          { required: 'Please add a surname' })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='surname'
                            render={({ message }) =>
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formUserName'>
                        <Form.Label>First Name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control required placeholder='Max' name='name'
                                      isInvalid={!!errors.name}
                                      {...register('name',
                                          { required: 'Please add a name' })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='name'
                            render={({ message }) =>
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formUserMatrikelNr'>
                        <Form.Label>Matrikel Nr.</Form.Label>
                        <Form.Control placeholder='k12345678' name='matrikelNr'
                                      isInvalid={!!errors.matrikelNr}
                                      {...register('matrikelNr',
                                          {
                                              pattern: {
                                                  value: /^(a?k|vk?)\d{6,8}$/,
                                                  message: 'Incorrect format.'
                                              }
                                          })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='matrikelNr'
                            render={({ message }) =>
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formUserPhone'>
                        <Form.Label>Telephone</Form.Label>
                        <Form.Control required type='tel' placeholder='067700000000' name='phone'
                                      isInvalid={!!errors.phone}
                                      { ...register('phone') }
                        />
                        <ErrorMessage
                            errors={errors}
                            name='phone'
                            render={({ message }) =>
                                <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback> }
                        />
                    </Form.Group>
                    { formType === 'add' && !loggedUser && (
                        <Form.Group className='mb-3' controlId='formCaptcha'>
                            <Form.Label>
                                Human verification <span className='text-danger'>*</span>
                            </Form.Label>
                            <Form.Control
                                placeholder='Type the challenge here'
                                name='captcha'
                                aria-describedby='captchaHelpBlock'
                                isInvalid={!!errors.captcha}
                                {...register('captcha', {
                                    required: 'Please complete the human verification challenge',
                                    pattern: {
                                        value: /^klaus|((?:prof\.?|dr\.?)?\s*(?:klaus\s+)?miesenberger)$/i,
                                        message: 'The answer is not correct',
                                    }
                                })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name='captcha'
                                render={({ message }) => (
                                    <Form.Control.Feedback type='invalid'>
                                        {message}
                                    </Form.Control.Feedback>
                                )}
                            />
                            <Form.Text className='text-muted' id='captchaHelpBlock'>
                                Type the name of the Head of the IIS Department
                            </Form.Text>
                        </Form.Group>
                    )}
                    <Button id='btn-save' name='btn-save' variant='primary' type='submit'>
                        Submit
                    </Button>
                    { formType === 'edit' &&
                        <Button id='btn-delete' name='btn-delete' variant='danger' style={{ marginLeft: 25 }}
                                onClick={() => setShowModal(true) }>
                            Delete User
                        </Button>
                    }
                    <Button id='btn-back' name='btn-back' variant='outline-dark' style={{ marginLeft: 25 }}
                            onClick={() => navigate(backURL)}>
                        Back
                    </Button>
                </Form></Card.Body></Card>
            </Container>
        </>
    )
}

export default RegistrationForm