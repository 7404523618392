import examService from '../services/exams'
import sessionService from '../services/sessions'

import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import './Lists.css'

import util from '../utils/util'
import { handleSort } from '../utils/componentUtil'


const ExamsList = ({ loggedUser, displayMessage }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [exams, setExams] = useState([])
    const [sortColumn, setSortColumn] = useState(null)
    const [sortDirection, setSortDirection] = useState(1) // 1 for ascending, -1 for descending
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)


    const { successMessage } = location.state || {}

    const isEmpty = value => value == null || value.length === 0
    const noExamsStyle = {
        color: 'red',
        textAlign: 'center',
        verticalAlign: 'middle',
    }

    const filterExamsForUser = async exams => {
        if (!loggedUser || !('role' in loggedUser)) {
            return []
        }
        if (loggedUser.role === 'student') {
            const examsWithAccess = []
            const sessionsOfStudent = await sessionService.getAll(`?approved=1&deleted=0&student=${loggedUser.id}`)
            const examIDs = sessionsOfStudent.map(s => s.exam)
            for (let exam of exams) {
                if (examIDs.includes(exam.id)) {
                    examsWithAccess.push(exam)
                }
            }
            return examsWithAccess
        } else {
            return exams
        }
    }

    useEffect(() => {
        examService.getAll()
            .then(exams => {
                filterExamsForUser(exams)
                    .then(filteredExams => {
                        filteredExams.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                        setExams(filteredExams)
                        setError(false)
                    })
                    .catch(error => {
                        setError(true)
                        console.error('Error fetching sessions:', error.response?.data?.error ?? error.message)
                    })
            })
            .catch(error => {
                setError(true)
                console.error('Error fetching exams:', error.response?.data?.error ?? error.message)
            })
            .finally(() => setLoaded(true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const memoizedDisplayMessage = useCallback(displayMessage, [displayMessage])
    useEffect(() => {
        if (successMessage) {
            memoizedDisplayMessage(null, successMessage, 'success', 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMessage])

    const counter = exams.length > 1 ? `${exams.length} exams` : null

    const sortedExams = exams.slice().sort((a, b) => {
        if (sortColumn) {
            if (['title', 'lva', 'startDate'].includes(sortColumn)) {
                return (a[sortColumn].localeCompare(b[sortColumn])) * sortDirection
            }
            return (a[sortColumn] - b[sortColumn]) * sortDirection
        }
        return 0
    })

    return (
        loggedUser &&
        <Container fluid>
            <h1>Exams List</h1>
            <div className='d-grid gap-2'>
                <Button variant='outline-primary' size='md' onClick={() => navigate(`/exam/`)}>
                    Add a new Exam
                </Button>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th className='sortable-header' onClick={() => handleSort('title', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Title
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('lva', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            LVA-Nr.
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('startDate', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Start Date
                        </th>
                        <th className='sortable-header' onClick={() => handleSort('durationMinutes', sortColumn, setSortColumn, sortDirection, setSortDirection)}>
                            Duration (min.)
                        </th>
                        <th>Manage Sessions</th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!isEmpty(sortedExams) && sortedExams.map(exam => (
                        <tr key={exam.id}>
                            <td>{exam.title && exam.title}</td>
                            <td>{exam.lva && exam.lva}</td>
                            <td>{exam.startDate && util.humanDateShort(exam.startDate)}</td>
                            <td>{exam.durationMinutes && exam.durationMinutes}</td>
                            <td>
                                <Button variant='link' onClick={() => navigate(`/exam/${exam.id}/sessions`)}>
                                    Sessions
                                </Button>
                            </td>
                            <td>
                                <Button variant='link' onClick={() => navigate(`/exam/${exam.id}`)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {isEmpty(sortedExams) && <tr>
                        <td style={noExamsStyle} colSpan='6'>
                            {error && 'Error fetching exams list.'}
                            {!error && !loaded && 'Loading...'}
                            {!error && loaded && 'No exams added yet!'}
                        </td>
                    </tr>}
                    </tbody>
                </Table>
                { counter && <p>There are <strong>{counter}</strong></p> }
            </div>
        </Container>
    )
}

export default ExamsList