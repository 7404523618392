import Nav from 'react-bootstrap/Nav'
import { LinkContainer } from 'react-router-bootstrap'

const NavigationSidebar = ({ loggedUser }) => {
    return (
        <Nav fill variant='tabs' defaultActiveKey='/' className='flex-md-column'>
            <LinkContainer to='/'>
                <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            { loggedUser &&
            <>
                { loggedUser.role === 'student' &&
                    <LinkContainer to='/exams'>
                        <Nav.Link>My Exams</Nav.Link>
                    </LinkContainer>
                }
                { ['admin', 'service'].includes(loggedUser.role) &&
                    <>
                    <LinkContainer to='/exams'>
                        <Nav.Link>Exam Overview</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/users'>
                        <Nav.Link>All Users</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/tutors'>
                        <Nav.Link>Tutors</Nav.Link>
                    </LinkContainer>
                    </>
                }
                { ['admin', 'service', 'tutor'].includes(loggedUser.role) &&
                <LinkContainer to='/students'>
                    <Nav.Link>Students</Nav.Link>
                </LinkContainer>
                }
            </>
            }
        </Nav>
    )
}

export default NavigationSidebar
