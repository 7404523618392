import Alert from 'react-bootstrap/Alert';

const InfoBox = ({ header, message, variant }) => {
    if (message === null) {
        return null
    }
    window.scrollTo(0, 0);
    if (header) {
        return (
            <Alert id='info-box' variant={variant || 'primary'} dismissible>
                <Alert.Heading>{header}</Alert.Heading>
                <p>
                    { message }
                </p>
            </Alert>
        )
    } else {
        return (
            <Alert id='info-box' variant={variant || 'primary'} dismissible>
                    { message }
            </Alert>
        )
    }
}

export default InfoBox