const MainContent = ({loggedUser}) => {
    return (
        <main>
            <p>Welcome to the IIS Exam Management System.</p>
            { !loggedUser && <p>Please log in using the button above.</p> }
            { loggedUser && <p>You can choose an option from the menu.</p> }
        </main>
    )
}

export default MainContent