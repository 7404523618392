import { Buffer } from 'buffer'

const capitalizeFirstLetter = input => input.charAt(0).toUpperCase() + input.slice(1)

const commaSeparatedString = (array, conj='and') => {
    let finalString
    const string = array.join(', ')
    const lastIndex = string.lastIndexOf(', ')
    if (lastIndex !== -1) {
        finalString = string.substring(0, lastIndex) + ', ' + conj + string.substring(lastIndex + 1)
    } else {
        finalString = string
    }
    return finalString
}

/**
 * Convert a File object (uploaded via an HTML form) to a Buffer object that can be sent to the backend.
 * @param file File uploaded via an HTML input field.
 * @returns {Promise<unknown>} A Promise containing the resulting Buffer, or an error.
 */
const readFile = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = function(event) {
            const fileBuffer = Buffer.from(event.target.result)
            resolve(fileBuffer)
        }
        fileReader.onerror = function(event) {
            reject(event.target.error)
        }
        fileReader.readAsArrayBuffer(file)
    })
}

const humanDateLong = originalDate => {
    if (!originalDate) {
        return ''
    }
    const date = new Date(originalDate)
    const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
    return dateTimeFormat.format(date)
}

const humanDateShort = originalDate => {
    const parts = originalDate.split('T')
    const datePart = parts[0]
    const timePart = parts[1].slice(0, 5)
    const [year, month, day] = datePart.split('-')
    return `${day}/${month}/${year} - ${timePart}`
}

/**
 * Return whether the given object is empty
 * @param value The value to check
 * @param strict False if non-objects are considered empty; otherwise only return true if value === {}. N.B. null is
 * always considered to be empty
 * @returns {*|boolean}
 */
const isEmptyObject = (value, strict=false) => {
    if (value == null) {
        return true
    }
    if (typeof value !== 'object') {
        return !strict
    }
    const proto = Object.getPrototypeOf(value)
    if (proto !== null && proto !== Object.prototype) {
        return !strict
    }
    for (const prop in value) {
        if (Object.hasOwn(value, prop)) {
            return false
        }
    }
    return true
}

const util = {
    capitalizeFirstLetter,
    commaSeparatedString,
    readFile,
    humanDateLong,
    humanDateShort,
    isEmptyObject
}

export default util