import genericService from './generic'
import userService from './users'

const baseUrl = '/api/students'

const getAll = (queryString='/') => {
    return genericService.getAll(baseUrl, queryString)
}

const getActive= () => {
    return genericService.getActive(baseUrl)
}

const getByID = id => {
    return genericService.getByID(baseUrl, id)
}

const create = newObject => {
    return genericService.createInstanceWithUser(baseUrl, newObject)
}

const deleteStudent = id => {
    return genericService.deleteByID(baseUrl, id)
        .then(() => {
            userService.update(id, {role: 'none'})
        })
}

const update = (id, newObject) => {
    return genericService.updateInstanceWithUser(baseUrl, id, newObject)
}

const studentService = {
    getAll,
    getByID,
    getActive,
    create,
    deleteStudent,
    update
}

export default studentService